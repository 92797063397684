import React from "react"
import Layout from "../components/layout"

export const seo = {
  title: "Terms & Conditions | Crampete",
  description:
    "Learn Full Stack Web Development, Data Science, Digital Marketing, and other in-demand tech skills via online, classroom and self-paced courses. It's time to up-skill!",
  keywords:
    "Full Stack developer online course, front end, back end, data science, digital marketing",
  image: "",
  url: "https://www.crampete.com",
  author: "Crampete",
}

const termsAndConditions = () => {
    return (
        <Layout title={seo.title}
        description={seo.description}
        image={seo.image}
        keywords={seo.keywords}
        url={seo.url}
        author={seo.author} >
            <section>
                <div className="container">
                <h3>Terms and Conditions</h3>
                    <p>The terms “we” and “us” refers to Crampete Learning Private Limited and its affiliates, and the term “you” refers to you, the student of the Program. Your enrolment in, and completion of, the Program is governed by the following terms of service (“<b>Terms</b>”). Please review the Terms carefully.</p>

                    <p>Before you commence the Program, you must read all of the Terms herein. By commencing the Program, you understand and agree that we will treat the same as acceptance of these Terms. If you do not agree to be bound by these Terms, you may not commence the Program.</p>

                    <p>We reserve the right at any time, at our sole discretion, to change or otherwise modify the Terms without prior notice, and your continued enrolment in, and access to, the Program signifies your acceptance of the updated or modified Terms. You are required to check the <a href="https://www.crampete.com/terms-and-conditions" target="_blank">www.crampete.com/terms-and-conditions</a> web page from time to time to make yourself aware of any amendments to the Terms.</p>

                    <p>In order to successfully complete the Program, you shall be required to fulfil the following minimum criteria:</p>
                    <ul>
                        <li>You shall maintain a minimum attendance of 80% in all classes/subjects offered as part of the Program</li>
                        <li>You shall hand-in all projects, assignments and other submissions to the mentor panel for evaluation, prior to the deadlines for the same.</li>
                        <li>You shall complete the hands-on lab sessions on a regular basis, as per the requirements laid down by us as part of the Program.</li>
                        <li>You shall complete all assessments on time with a minimum 80% score, prior to the deadlines for the same.</li>
                        <li>You must have paid the fees before the commencement of your classes or as per the agreed instalment schedule (refer to the “Fee Details” table above).</li>
                        <li>You will be eligible for any placement assistance only if you meet the above criteria.</li>
                    </ul>
                    <p>Payment terms:</p>
                    <ul>
                        <li>You shall pay the full Program fee before the commencement of your classes.</li>
                        <li>Notwithstanding the above, part payment of the Program fee may be allowed solely at our discretion. In such case, you shall be required to provide Post Dated Cheques (PDCs) for the full amount prior to your commencement of the Program. The PDCs shall be provided for such amounts and dates determined by us. In case of dishonour of any PDC, you shall be liable to be dismissed from the Program and shall not be entitled to any refund whatsoever.</li>
                        <li>As we are a training partner and the Fee paid by the students are for training purpose only. The Fee once paid will not be refunded under any circumstances However, the fee may be refunded only if our company fails to start a course (Not applicable for unavoidable instances). Delay in starting of the class by fortnight shall not be considered as not starting.</li>
                    </ul>
                    <p>This terms and conditions is effective as of 5th May 2021.</p>
                </div>
            </section>
        </Layout>
    )
}

export default termsAndConditions